.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-bottom: 20px;
  color: rgba(34, 40, 49, 1);
  margin-top: 10px;
}

.page {
  width: 35px;
  height: 35px;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #000;
  cursor: pointer;
  border-radius: 50%;
  background-color: #f3f2f7;
  margin-left: 1px;
}

.page.previous,
.page.next {
  width: max-content;
  padding: 0 7px;
}

.page.previous {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f3f2f7;
}

.page.next {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f3f2f7;
}

.previous {
  margin-left: 2px;
}

.next {
  margin-right: 2px;
}

.pagination .activePagi {
  background-color: #7367f0;
  color: #fff;
}

button:disabled {
  cursor: not-allowed;
}