@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .scrollbar-hide {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .scrollbar-hide::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari and Opera */
    }
}

* {
    direction: rtl;
}
.active {
    background: linear-gradient(-118deg, #7367f0, rgba(115, 103, 240, .7));
    color: #fff;
    padding: 2px;
    height: 37px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7);
    box-shadow: 0 0 10px 1px rgba(115, 103, 240, .7)
}

